import React from "react"
import "./ProductDetails.scss"
import PropTypes from "prop-types"

export default function ProductDetails({ tagline, subtitle, components }) {
  if (components.length === 0) {
    return null
  }
  return (
    <section className="section product-page-details">
      <div className="container">
        <div className="product-page-details-wrapper">
          <h1 className="title has-text-centered">{tagline}</h1>
          {/* <div className="subtitle has-text-centered">{subtitle}</div> */}
          <div className="product-page-details-wrapper_screenshots">
            {components.map((component, index) => {
              return (
                <div key={index}>
                  <div
                    className={`columns  ${
                      index === 0 || index % 2 === 0 ? "reverse-columns" : ""
                    } `}
                  >
                    <div
                      className={`column is-7 product-page-details-wrapper_screenshots_screenshot ${
                        index === 0 || index % 2 === 0
                          ? "has-text-right"
                          : "has-text-left"
                      } `}
                    >
                      <img
                        src={`${component.Product_Component_Image.publicURL}`}
                        alt={component.Name}
                      />
                    </div>
                    <div className="column is-5 product-page-details-wrapper_screenshots_info">
                      <h2 className="screenshot-title">{component.Name}</h2>
                      <div className="screenshot-description">
                        {component.Description}
                      </div>
                    </div>
                  </div>
                  {index !== components.length - 1 ? <hr /> : null}
                </div>

                // <div key={index}>
                //   <div>
                //     <div
                //       className={`product-page-details-wrapper_screenshots_screenshot ${
                //         index === 0 || index % 2 === 0
                //           ? "has-text-right"
                //           : "has-text-left"
                //       } `}
                //     >
                //       <img
                //         src={`${component.Product_Component_Image.publicURL}`}
                //         alt={component.Name}
                //       />
                //     </div>
                //     <div className="column is-5 product-page-details-wrapper_screenshots_info">
                //       <div className="screenshot-title">{component.Name}</div>
                //       <div className="screenshot-description">
                //         {component.Description}
                //       </div>
                //     </div>
                //   </div>
                //   {index !== components.length - 1 ? <hr /> : null}
                // </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

ProductDetails.propTypes = {
  tagline: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  components: PropTypes.array,
}
