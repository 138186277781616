import React from "react"
import "./UseCase.scss"
import PropTypes from "prop-types"
export default function UseCase({ title, description }) {
  return (
    <div className="usecase-tile">
      <div className="upper-blue-banner"></div>
      <div className="lower-blue-banner"></div>
      <div className="usecase-tile__header is-flex ">
        <div className="usecase-tile__header-title">{title}</div>
        {/* <div className="usecase-tile__header-arrow">
          <svg
            className="arrow"
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.7004 7.87955L9.43445 0.0926166C9.35565 0.0332469 9.25511 0 9.14913 0H6.74433C6.54325 0 6.45086 0.218481 6.60303 0.33247L16.119 7.55183H0.217384C0.0978226 7.55183 0 7.63732 0 7.74181V9.16668C0 9.27117 0.0978226 9.35667 0.217384 9.35667H16.1163L6.60031 16.576C6.44814 16.6924 6.54053 16.9085 6.74161 16.9085H9.22793C9.27956 16.9085 9.33119 16.8919 9.36923 16.861L19.7004 9.02895C19.7945 8.95746 19.8699 8.86916 19.9216 8.77002C19.9733 8.67087 20 8.56319 20 8.45425C20 8.3453 19.9733 8.23762 19.9216 8.13848C19.8699 8.03934 19.7945 7.95104 19.7004 7.87955Z"
              fill="#CB6E17"
            />
          </svg>
        </div> */}
      </div>
      <div className="usecase-tile__body">{description}</div>
    </div>
  )
}

UseCase.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
