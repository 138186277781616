import React from "react"
import "./Hero.scss"
// import PrimaryAnchor from "../../PrimaryAnchor/PrimaryAnchor"
// import SecondaryAnchor from "../../SecondaryAnchor/SechondaryAnchor"
import PropTypes from "prop-types"
export default function Hero({
  name,
  description,
  callToAction1,
  callToAction2,
}) {
  return (
    <section id="#producthero" className="hero product-page-hero">
      <div className="hero-body">
        <div className="container">
          <div className="product-page-hero-wrapper">
            <h1 className="title">{name}</h1>
            <h2 className="subtitle">{description}</h2>
            {/* <div className="call-to-action-anchors is-flex">
              {callToAction1 ? (
                <PrimaryAnchor to="/">{callToAction1}</PrimaryAnchor>
              ) : null}
              {callToAction2 ? (
                <SecondaryAnchor to="/">{callToAction2}</SecondaryAnchor>
              ) : null}
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  callToAction1: PropTypes.string,
  callToAction2: PropTypes.string,
}
