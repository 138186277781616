import React from "react"
import Hero from "./Hero/Hero"
import Features from "./Features/Features"
import ProductDetails from "./ProductDetails/ProductDetails"
import UseCases from "./UseCases/UseCases"
import Layout from "../layout"
import SEO from "../seo"

export default function ProductPage({ pageContext }) {
  const product = pageContext.item.node
  console.log(product)
  return (
    <Layout>
      <SEO title={product.Name} />
      <Hero
        name={product.Name}
        description={product.Description}
        callToAction1={product.Call_To_Action_1}
        // callToAction2={product.Call_To_Action_2}
      />
      <Features
        features={product.productfeatures}
        image={product.Features_Image.publicURL}
        description={product.Features_Description}
      />
      <ProductDetails
        tagline={product.Tagline}
        subtitle={product.Subtitle}
        components={product.productcomponents}
      />
      <UseCases usecases={product.productusecases} />
    </Layout>
  )
}
