import React from "react"
import "./Features.scss"

const listIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="url(#paint0_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="-0.0557254"
        y1="7.97919"
        x2="15.9482"
        y2="7.97919"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A79D" />
        <stop offset="0.12" stopColor="#098A90" />
        <stop offset="0.3" stopColor="#14667F" />
        <stop offset="0.47" stopColor="#1D4972" />
        <stop offset="0.65" stopColor="#233568" />
        <stop offset="0.83" stopColor="#272963" />
        <stop offset="1" stopColor="#282561" />
      </linearGradient>
    </defs>
  </svg>
)

export default function Features({ features, image, description }) {
  if (features.length === 0) {
    return null
  }
  return (
    <section className="section product-page-features">
      <div className="container">
        <div className="product-page-features-wrapper has-text-centered">
          <div className="title">Key Features</div>
          <div className="product-page-features-wrapper_features">
            <h2 className="subtitle feature__list-description-title">
              {description}
            </h2>
            <div className="feature__list-image">
              <img src={image} alt="feature" />
            </div>
            <div className="feature__list-description">
              <ul className="columns is-multiline">
                {features.map((feature, index) => {
                  return (
                    <li
                      key={index}
                      className="column is-half-tablet is-full-mobile"
                    >
                      <div className="is-flex feature__list-item">
                        <div className="feature__list-item-icon">
                          {listIcon}
                        </div>
                        <div>{feature.Name}</div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
