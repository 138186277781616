import React from "react"
import "./UseCases.scss"
import UseCase from "../../UseCase/UseCase"
import PropTypes from "prop-types"

export default function UseCases({ usecases }) {
  if (!usecases || usecases.length === 0) {
    return null
  }
  return (
    <section className="section product-page-usecases">
      <div className="container">
        <div className="product-page-usecases-title">
          <div className="title has-text-centered">Use Cases</div>
        </div>
        <div className="columns is-multiline">
          {usecases.map((useCase, index) => (
            <div className="column is-4" key={index}>
              <UseCase title={useCase.Name} description={useCase.Description} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

UseCases.propTypes = {
  usecases: PropTypes.array,
}
